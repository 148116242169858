import axios from "axios";
import moment from "moment";

export const MyCourseSheets = async (token) => {
    console.debug("Fetching MyCourseSheets...");
    // For CIS TERMS: https://integration.unisg.ch/eventapi/CisTermAndPhaseInformations
    try {
        const res = await axios.get(
            // IF WE EVER CHANGE THIS; IT ALSO NEEDS TO BE CHANGED FOR saveCourse(event, token) and deleteCourse(event, token)
            `https://integration.unisg.ch/EventApi/CourseInformationSheets/myLatestPublishedPossiblebyTerm/74db9567-f466-44c3-a9d5-04cbecb0d084`,
            // `https://integration.unisg.ch/EventApi/CourseInformationSheets/myLatestPublishedPossiblebyTerm/df38c9b2-4db7-4389-8485-20fdb4318f40`,
            // `https://integration.unisg.ch/EventApi/CourseInformationSheets/myLatestPublishedPossiblebyTerm/d33babff-17c7-4997-b35b-a5348a2cb7bd`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return res.data;
    } catch (err) {
        return console.log("error" + err);
    }
};

// https://tools.unisg.ch/handlers/Public/CourseInformationSheet.ashx/semester/FS23/eventnumber/8,004,1.00.pdf
// https://tools.unisg.ch/handlers/Public/CourseInformationSheet.ashx/semester/HS22/eventnumber/8,004,1.00.pdf

export const MyCourses = async (token) => {
    console.debug("Fetching MyCourses...");
    try {
        const res = await axios.get(
            `https://integration.unisg.ch/EventApi/MyCourses/currentTerm`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return res.data;
    } catch (err) {
        return console.log(err);
    }
};

// Cleaning the data from the courses and coursesheets
// The output is the base data for all data displayed in the components
export const CleanCourses = (
    events,
    myCourses,
    courseRatings,
    userStudyPlan
) => {
    const myCourseIds = myCourses.map((course) => {
        return course.eventCourseNumber;
    });

    const courseRatingsObject = {};
    courseRatings.forEach((course) => {
        courseRatingsObject[course._id] = course.avgRating;
    });

    const courseRatingIDs = Object.keys(courseRatingsObject);

    const cleanEvents = events
        .map((event) => {
            const cleanCourse = event.courses.map((course) => {
                course.ects = event.credits / 100; 
                course.achievementContent = event.achievementContent;
                course.achievementFormStatus = event.achievementFormStatus;
                course.achievementLiterature = event.achievementLiterature;
                course.achievementParts = event.achievementParts;
                course.classification = event.classification;
                course.learningObjectives = event.learningObjectives;
                course.courseAdditionalInformation =
                    event.courseAdditionalInformation;
                course.courseContent = event.courseContent;
                course.courseLiterature = event.courseLiterature;
                course.parentCourseNumber = event.courseNumber;
                course.coursePrerequisites = event.coursePrerequisites;
                course.courseStructure = event.courseStructure;
                course.languageCode = event.courseLanguage.code;
                course.language = event.courseLanguage.shortName;
                course.parentName = event.shortName;
                course.selected = false;
                course.avgRating = courseRatingIDs.includes(course.courseNumber)
                    ? courseRatingsObject[course.courseNumber].toFixed(1)
                    : null;
                if (myCourseIds.includes(course.courseNumber)) {
                    course.selected = true;
                    course.allocated = true;
                } else {
                    course.allocated = false;
                    course.selected = false;
                }

                course.calendar =
                    event.calendarEntry &&
                    event.calendarEntry.filter(
                        (cal) => cal.courseNumber === course.courseNumber
                    );

                // remove calendar entry when eventData is null
                course.calendar = course.calendar.filter(
                    (cal) => cal.eventDate !== null
                );

                course.calendar.map((cal) => {
                    cal.courseId = course.id;
                    cal.title = course.shortName;
                    cal.start = moment(cal.eventDate).format(
                        "YYYY-MM-DDTHH:mm:ss"
                    );
                    cal.end = moment(cal.start)
                        .add(cal.durationInMinutes, "m")
                        .format("YYYY-MM-DDTHH:mm:ss");
                    return cal;
                });
                return course;
            });
            return cleanCourse;
        })
        .flat();

    cleanEvents.forEach((course) => {
        if (
            userStudyPlan["74db9567-f466-44c3-a9d5-04cbecb0d084"].includes(
                course.id
            )
            // userStudyPlan["df38c9b2-4db7-4389-8485-20fdb4318f40"].includes(
            //     course.id
            // )
        ) {
            course.selected = true;
        }
    });

    return cleanEvents;
};

// Getting the average rating for all courses
export const AllCourseRatings = async (token) => {
    console.debug("Fetching AllCourseRatings...");
    try {
        const res = await axios.get(`https://api.shsg.ch/course-ratings`, {
            headers: {
                "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                "X-RequestedLanguage": "EN",
                "API-Version": "1",
                Authorization: `Bearer ${token}`,
            },
        });
        return res.data.filter((course) => course._id !== '7,721,1.00');
    } catch (err) {
        return console.log("error" + err);
    }
};

// Getting the average rating for an array of courses
export const AllCourseRatingsById = async (token, courseIds) => {
    console.debug("Fetching AllCourseRatingsById...");
    try {
        const res = await axios.get(
            `https://api.shsg.ch/course-ratings?ids=${courseIds}`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return res.data;
    } catch (err) {
        return console.log("error" + err);
    }
};

// Getting array of courses rated by a user
export const RatedCoursesForUser = async (token) => {
    console.debug("Fetching RatedCoursesForUser...");
    try {
        const res = await axios.get(
            `https://api.shsg.ch/course-ratings/of-user`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return res.data;
    } catch (err) {
        return console.log("error" + err);
    }
};

// Getting the course rating details for a specific course
export const CourseRatingDetailById = async (token, courseId) => {
    console.debug("Fetching CourseRatingDetailById...");
    try {
        const res = await axios.get(
            `https://api.shsg.ch/course-ratings/by-course/${courseId}`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return res.data.filter((course) => course.courseNumber !== '7,721,1.00');
    } catch (err) {
        return console.log("error" + err);
    }
};

// Adding a course rating for a specific course
export const SubmitCourseRatingById = async (token, courseRating) => {
    try {
        const res = await axios.post(
            `https://api.shsg.ch/course-ratings/`,
            courseRating,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(res.data);
    } catch (err) {
        console.log("error" + err);
    }
};

const CoursesBySemester = async (semesterId, token) => {
    try {
        const res = await axios.get(
            `https://integration.unisg.ch/eventapi/MyCourses/byTerm/${semesterId}`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return res.data;
    } catch (err) {
        return console.log("error" + err);
    }
};

// Getting all courses taken last semester
export const CoursesLastSemester = async (token) => {
    // const fall20 = "344fffec-99b6-4b09-aadc-ae1f11bf63e0" // Fall20
    // const spring21 = "a8fbfdcf-da5a-4327-8e24-cdce6396aaa8" // Spring21
    const fall21 = "ba8c222e-1d66-4a6f-88c6-d9739715d671"; // Fall21
    const spring22 = "b987f42f-0c9b-41b2-b8af-97844b0e939d"; // Spring22
    const fall22 = "67780ec0-88e3-4095-a998-2dbf77921493"
    const spring23 = "180fed8a-9db7-4e6e-aebb-22f6959b0f42"

    // const coursesSpring21 = await CoursesBySemester(spring21, token)
    // coursesSpring21.forEach((course) => { course.semesterName = "Spring 2021" })

    const coursesFall21 = await CoursesBySemester(fall21, token);
    coursesFall21.forEach((course) => {
        course.semesterName = "Fall 21";
    });

    const coursesSpring22 = await CoursesBySemester(spring22, token);
    coursesSpring22.forEach((course) => {
        course.semesterName = "Spring 22";
    });

    const coursesFall22 = await CoursesBySemester(fall22, token);
    coursesFall22.forEach((course) => {
        course.semesterName = "Fall 22";
    });

    const coursesSpring23 = await CoursesBySemester(spring23, token);
    coursesSpring23.forEach((course) => {
        course.semesterName = "Spring 23";
    });

    return [].concat(
        coursesSpring23,
        coursesFall22,
        coursesSpring22,
        coursesFall21
    );
};

export const saveCourse = async (event, token) => {
    try {
        const res = await axios.post(
            `https://api.shsg.ch/study-plans/74db9567-f466-44c3-a9d5-04cbecb0d084/${event.id}`,
            {},
            // `https://api.shsg.ch/study-plans/df38c9b2-4db7-4389-8485-20fdb4318f40/${event.id}`, {},
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return res.data;
    } catch (err) {
        return console.log(err);
    }
};

export const deleteCourse = async (event, token) => {
    try {
        const res = await axios.delete(
            `https://api.shsg.ch/study-plans/74db9567-f466-44c3-a9d5-04cbecb0d084/${event.id}`,
            // `https://api.shsg.ch/study-plans/df38c9b2-4db7-4389-8485-20fdb4318f40/${event.id}`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return res.data;
    } catch (err) {
        return console.log(err);
    }
};

export async function getStudyPlan(accessToken) {
    try {
        const res = await axios.get(`https://api.shsg.ch/study-plans`, {
            headers: {
                "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                "X-RequestedLanguage": "EN",
                "API-Version": "1",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (Object.keys(res.data).length === 0) {
            const queryParams = new URLSearchParams(window.location.search).get(
                "courses"
            );
            if (queryParams) {
                try {
                    const res = await axios.post(
                        `https://api.shsg.ch/study-plans`,
                        {
                            semesters: {
                                "74db9567-f466-44c3-a9d5-04cbecb0d084":
                                    queryParams.split(","),
                                // "df38c9b2-4db7-4389-8485-20fdb4318f40": queryParams.split(',')
                            },
                        },
                        {
                            headers: {
                                "X-ApplicationId":
                                    "820e077d-4c13-45b8-b092-4599d78d45ec",
                                "X-RequestedLanguage": "EN",
                                "API-Version": "1",
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );

                    return res.data;
                } catch (err) {
                    return console.log(err);
                }
            }
        }

        return res.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function initialStudyPlan(accessToken) {
    try {
        const res = await axios.get(`https://api.shsg.ch/study-plans`, {
            headers: {
                "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                "X-RequestedLanguage": "EN",
                "API-Version": "1",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (Object.keys(res.data).length === 0) {
            const queryParams = window.localStorage.getItem("selectedEvents");

            if (queryParams) {
                try {
                    const res = await axios.post(
                        `https://api.shsg.ch/study-plans`,
                        {
                            semesters: {
                                "74db9567-f466-44c3-a9d5-04cbecb0d084":
                                    queryParams.split(","),
                                // "df38c9b2-4db7-4389-8485-20fdb4318f40": queryParams.split(',')
                            },
                        },
                        {
                            headers: {
                                "X-ApplicationId":
                                    "820e077d-4c13-45b8-b092-4599d78d45ec",
                                "X-RequestedLanguage": "EN",
                                "API-Version": "1",
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );

                    return res.data;
                } catch (err) {
                    return console.log(err);
                }
            }
        }

        return res.data;
    } catch (err) {
        return console.log(err);
    }
}