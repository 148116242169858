import { atom, selector } from "recoil";
// import { coursesSelectedSemesterQuery } from "./coursesSelectedSemester";
import { scorecardGraduationState } from "../hsg/scorecardGraduation";

// export const classificationsFilterOptionsState = atom({
//     key: "classificationFilterOptions",
//     default: selector({
//         key: "classificationsFilterOptions",
//         get: async ({ get }) => {
//             const courses = get(coursesSelectedSemesterQuery);

//             const classificationsFilterOptions = [
//                 ...new Set(courses.map((course) => course.classification)),
//             ]
//                 .sort()
//                 .map((classification) => {
//                     return { label: classification, value: classification };
//                 });

//             return classificationsFilterOptions;
//         },
//     }),
// });

export const classificationsFilterOptionsState = atom({
    key: "classificationsFilterOptions2",
    default: selector({
        key: "classificationsFilterOptions/Default",
        get: async ({ get }) => {
            const scorecard = get(scorecardGraduationState).items[0];
            const data = (scorecard && scorecard.items) ? scorecard.items.map((group) => { // fix items.map issue (22.08.24)
                return {
                    label: group.description,
                    options: flattenScorecard(group),
                };
            }) : [];
            return data;
        },
    }),
});

function flattenScorecard(topScorecard) {
    const temp = (topScorecard.items || []).map((bottomScorecard) => { // fix map issue
        if (
            // ["Compulsory Subjects", "Pflichtbereich"].includes(
            //     bottomScorecard.description
            // ) ||
            bottomScorecard.items &&
                (bottomScorecard.items.filter((item) => item.isTitle) || []).length > 0
        ) {
            return flattenScorecard(bottomScorecard);
        } else {
            return {
                value: bottomScorecard.description,
                label: bottomScorecard.description,
            };
        }
    });
    return temp.flat();
}
