import { ErrorCodeBlock } from "./ErrorCodeBlock";
import { SendErrorButton } from "./SendErrorButton";

export function PagewideErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div className="bg-gray-50 h-screen w-screen flex items-center justify-center">
            <div className="mx-auto max-w-6xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    Ooops, Biddit broke.
                </h2>
                <p className="mx-auto mt-6 text-lg leading-8 text-gray-600">
                    Biddit was unable combine various pieces of data it received
                    from the HSG servers.
                    <br />
                    This is usually because the data included unexpected or
                    non-matching information.
                    <br />
                    <br />
                    Send us the error by clicking the button below or copying
                    the text and sending it to{" "}
                    <span className="font-bold">biddit@shsg.ch</span>.<br />
                    This helps us fix the issue for you and other students.
                </p>
                <ErrorCodeBlock error={error} />
                <p className="mx-auto mt-2 text-lg leading-8 text-gray-600">
                    You can also use the previous, more stable version of
                    Biddit.
                </p>

                <div className="mt-8 flex items-center justify-center gap-x-6">
                    <SendErrorButton
                        error={error}
                        buttonColor={"bg-hsg-600"}
                        buttonHovorColor={"bg-hsg-500"}
                    />
                    <a
                        href="/legacy"
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        Open Legacy Biddit <span aria-hidden="true">→</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
